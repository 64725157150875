import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
        color: 'white'
    }
});


function ListFiles(props) {
    const text = props.text;
    const classes = useStyles();

    const listItems = text.map((file) => <li className={classes.link}><a className={classes.link} href={"https://www.ironstone-tech.com/api/get_output/" + file.split(':')[0]} download>{file}</a></li>);
    return (
      <ul>{listItems}</ul>
    );
}


function Download() {
    const [files, setFiles] = useState([]);
    const classes = useStyles();

    useEffect (() => {
        fetch('https://www.ironstone-tech.com/api/output').then(res => res.json()).then((result) =>{
            setFiles(result.files);
        });
    }, []);

    return (
        <div className="page">
            <Link to="/"><button className="button1">
                Home
            </button></Link>
            <ListFiles text={files} />
        </div>
    );
};

export default Download;
