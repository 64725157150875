import './App.css';
import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Upload from "./pages/upload/Upload";
import Home from "./pages/home/Home";
import Download from "./pages/download/Download";
import Log from "./pages/log/Log";
import Dlog from "./pages/detail_log/Log";

const App = () => {
  return (
    <div className="App">
    <Router>
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/upload-property' component={Upload}></Route>
      <Route exact path='/upload-tenant' component={Upload}></Route>
      <Route exact path='/download' component={Download}></Route>
      <Route exact path='/log' component={Log}></Route>
      <Route exact path='/dlog' component={Dlog}></Route>
    </Switch>
    </Router>
    </div>
  );
}

export default App;
