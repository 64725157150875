import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import './Log.css'


function NewlineText(props) {
    const text = props.text;
    const newText = text.split('\n').map((str) => <p className="text">{str}</p>);

    return newText;
}

function Log() {
    const [date, setDate] = useState("");
    const [data, setData] = useState("");
    const [status, setStatus] = useState("");
    useEffect (() => {
        fetch('https://www.ironstone-tech.com/api/log').then(res => res.json()).then((result) =>{
            setDate(result.last_modified);
            setData(result.log);
            setStatus(result.status);
        });
    }, []);

    return (
        <div className="page">
            <Link to="/"><button className="button1">
                Home
            </button></Link>
            <div className="logs">
                <NewlineText text={data} />
            </div>
            <br/><br/>
            <p className="text">Date Last Modified: {date}</p>
        </div>
    );
};

export default Log;
