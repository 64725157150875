import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core/';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    colorPrimary: {
        color: "#ffffff !important",
        padding: '15px;'
    },
    textProgress: {
        color: "#ffffff",
        fontSize: 20,
    }
});

export default function CircularProgressWithLabel(props) {
    const classes = useStyles();
    // const { perc, isStarted }= props;
    const perc = props.perc;
    const isStarted = props.started;
    const getCircularProgressText = () => {
        let text = "";
        if(isStarted) {
            text = `${Math.round(perc,)}% Complete`;
        } else if (!isStarted) {
            text = "Upload file."
        // } else if (condition) {
            // text = "45 Min Left"
        // }
        }
        return text;
    }
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={ !isStarted ? 100 : perc} size={250} thickness={1} classes={{colorPrimary: classes.colorPrimary}}/>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" className={classes.textProgress}>{getCircularProgressText()}</Typography>
      </Box>
    </Box>
  );
}

