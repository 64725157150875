import React, {Component} from 'react'
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import './Upload.css'
import logo from '../../images/logo.png'

function FileList(props) {
  const files = props.files
  const listItems = files.map((file) =>
    <li>{file}</li>
  );
  return (
    <ul className="text">{listItems}</ul>
  );
}


class App extends Component {
    state = {

      // Initially, no file is selected
      selectedFile: null, 
      status: 'Idle',
      files: [],
      completedFiles: [],
      type: window.location.href.split("-").pop()
    };

    getCompletedFiles(){
        console.log(this.state.type);
        fetch('https://www.ironstone-tech.com/api/completed-'+this.state.type).then(res => res.json()).then((result) =>{
            if(result.files.length > 10){
                this.setState({completedFiles: result.files.slice(0, 10)})
            }else{
                this.setState({completedFiles: result.files})
            }
        });
        fetch('https://www.ironstone-tech.com/api/uploads-'+this.state.type).then(res => res.json()).then((result) =>{
            if(result.files.length > 10){
                this.setState({files: result.files.slice(0, 10)})
            }else{
                this.setState({files: result.files})
            }
        });
    }

    componentDidMount() {
        this.getCompletedFiles();
    }

    startScrape = () => {
        fetch('https://www.ironstone-tech.com/api/start-'+this.state.type+'-scrape').then(res => this.setState({status: res.json().status}));
    }

    // On file select (from the pop up)
    onFileChange = event => {

      // Update the state
      this.setState({ 
          selectedFile: event.target.files[0],
          status: "Idle"
      });
    };

    // On file upload (click the upload button)
    onFileUpload = () => {
        
      if (!this.state.selectedFile) {
          fetch('https://www.ironstone-tech.com/api/uploads-'+this.state.type).then(res => res.json()).then(result =>
              this.setState({status: "No Files found to upload", files: result.files}));
      }else{
    
      const data = new FormData()
      // Details of the uploaded file
      data.append('file', this.state.selectedFile, this.state.selectedFile.name)
      console.log(data);

      console.log(data);
      fetch('https://www.ironstone-tech.com/api/upload-'+this.state.type, {
          method: 'POST', 
          headers: {
            'content-type': 'multipart/form-data'
          },
          body: data})
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                this.setState({status: result.status});
                if(result.status === "SUCCESS"){
                    this.setState({selectedFile: null, files: result.files});
                }
            });
      }
    };

    onClearFiles = () => {
        fetch('https://www.ironstone-tech.com/api/clear-'+this.state.type).then(res => res.json()).then((result) => {
            console.log(result);
            if(result.status === "SUCCESS"){
                if(result.files.length > 10){
                    this.setState({files: result.files.slice(0, 10)})
                }else{
                    this.setState({files: result.files})
                }
            }
        });
    };

    // File content to be displayed after
    // file upload is complete
    fileData = () => {

      if (this.state.selectedFile) {

        return (
          <div className="text">
            <h2>File Details:</h2>
            <p>File Name: {this.state.selectedFile.name}</p>
            <p>File Type: {this.state.selectedFile.type}</p>
            <p>Status: {this.state.status}</p>
          </div>
        );
      } else {
        return (
          <div className="text">
            <br />
            <h4>Choose before Pressing the Upload button</h4>
            <p>File Status: {this.state.status}</p>
          </div>
        );
      }
    };

    render() {

      return (
        <Grid container direction="column" alignItems="center" justify="space-around" className="root">
        <Grid container alignItems="center" justify="space-between" className="appBar">
            <Grid item>
              <Link to="/" className="link"><img src={logo} alt="Home" className="logo"/></Link>
            </Grid>
        </Grid>
        <Grid item>
            <h1 className="text">
              Upload files to scrape!
            </h1>
        </Grid>
        <Grid container direction="row" justify="center">
              <input type="file" className="fileInput" onChange={this.onFileChange} />
              <button onClick={this.onFileUpload}>
                Upload!
              </button>
              <button onClick={this.onClearFiles}>
                  Clear!
              </button>
          <Link to="/" className="link">
              <button className="start" onClick={this.startScrape}>
                  Start Scrape
              </button>
          </Link>
          </Grid>
        {this.fileData()}
        <Grid container direction="row" justify="space-around">
          <Grid item xs={1}/>
          <Grid item xs={4}>
            <h3 className="text">Files Uploaded and Ready to scrape:</h3>
            <FileList files={this.state.files}/>
          </Grid>
          <Grid item xs={2}/>
          <Grid ite xs={4}>
            <h3 className="text">Scraping of these files were completed:</h3>
            <FileList files={this.state.completedFiles}/>
          </Grid>
          <Grid item xs={1}/>
        </Grid>
        </Grid>
      );
    }
  };

  export default App;
