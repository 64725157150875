import React, {Component} from 'react'
import { Link } from "react-router-dom";
import Progress from "../../components/Progress"
import './Status.css'
import { Button, Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme =>({
    typography: {
        color: "#ffffff",
    },
    link: {
        textDecoration: 'none'
    },
    button: {
        backgroundColor: "#ffffff",
        textTransform: 'none',
        height: 60,
        width: 400,
        "&:hover": {
            backgroundColor: "#5B9483",
            color: "#fff"
        }
    },
    button1: {
        backgroundColor: "#5B9483",
        color: "#fff",
        textTransform: 'none',
        textDecoration: 'none',
        height: 60,
        width: 400,
        "&:hover": {
            backgroundColor: "#ffffff",
        }
    }
});

class Status extends Component {
    state = {
        date: "",
        perc: 0,
        isStarted: false,
        timeLeft: "",
        average: "",
        scrapeCompleted: false,
        outputName: ""
    }

    stopScrape = () => {
        fetch('https://www.ironstone-tech.com//api/stop-scrape');
        this.setState({isStarted: false});
    }

    getData = () => {
        fetch('https://www.ironstone-tech.com/api/status').then(res => res.json()).then((result) =>{
            this.setState({
                date: result.last_modified, 
                perc: result.percentage_done, 
                timeLeft: result.time_left, 
                average: result.average_time, 
                isStarted: result.status,
                scrapeCompleted: result.completed_status,
                outputName: result.filename
            });
            console.log(this.state.isStarted);
        });
    }

    updateStatus = () => {
        if (this.state.isStarted){
            this.getData();
        }
    }

    componentDidMount() {
        setTimeout(this.getData, 2000);
        setInterval(this.updateStatus, 10000);
    }

    downloadOutputFile = () => {
        fetch("https://www.ironstone-tech.com/api/get_output/" + this.state.outputName)
        fetch('https://www.ironstone-tech.com/api/clear-completed-file');
        this.setState({
            scrapeCompleted: false,
            outputName: ""
        });
    }

    render() {
        const { classes }  = this.props;
        if (this.state.isStarted){
            return (
                <Grid container direction="column" className="page" alignItems="center">
                    <Progress started={this.state.isStarted} className={classes.progress} perc={this.state.perc}/>
                    <span className="text">Last Modified: {this.state.date} Time Left: {this.state.timeLeft}m Percentage: {this.state.perc}%</span>
                    <br/>
                    <button onClick={this.stopScrape} className="button1">         
                        Click here to stop the scrape
                    </button>
                </Grid>
            );
        } else if (this.state.scrapeCompleted){
            return (
                <Grid container direction="column" className="page" alignItems="center">
                    <Progress started={this.state.isStarted} className={classes.progress} perc={this.state.perc}/>
                    <Button onClick={this.downloadOutputFile} className={classes.button1}>
                        Click here to Download recent Scrape
                    </Button>
                </Grid>
            );
        } else {
            return(
                <Grid container direction="column" className="page" alignItems="center">
                    <Progress started={this.state.isStarted} className={classes.progress} perc={this.state.perc}/>
                </Grid>
            );
        }
    }
}

export default withStyles(useStyles)(Status)
