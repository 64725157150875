import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Home.css'
import Status from '../status/Status'
import ReactDOM from 'react-dom';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.png'

const useStyles = makeStyles({
    root: {
        height: 'inherit',
        width: 'inherit'
    },
    logo: {
        height: "100px",
        width: "350px"
    },
    appBar: {
        paddingLeft: '4vw',
        paddingRight: '4vw',
        height: '10vh',
        width: '100vw'
    },
    buttonUpload: {
        backgroundColor: "#5B9483",
        textTransform: 'none',
        height: 60,
        width: 400,
        color: "#fff",
        "&:hover": {
            backgroundColor: "#ffffff"
        }
    },
    button: {
        backgroundColor: "#ffffff",
        textTransform: 'none',
        height: 60,
        width: 400,
        "&:hover": {
            backgroundColor: "#5B9483",
            color: "#fff"
        }
    },
    buttonsTop: {
        marginLeft: "10px",
        marginRight: "10px",
        backgroundColor: "#ffffff",
        textTransform: 'none',
        height: 45,
        width: 200,
        "&:hover": {
            backgroundColor: "#5B9483",
            color: "#fff"
        }
    },
    link: {
        textDecoration: 'none'
    }
});


const Home = () => {
    const [progress, setProgress] = useState(0);
    const classes = useStyles();

    return (
        <Grid container direction="column" alignItems="center" justify="space-around" className={classes.root}>
            <Grid container alignItems="center" justify="space-between" className={classes.appBar}>
                <Grid item>
                <Link to="/" className={classes.link}><IconButton><img src={logo} className={classes.logo}/></IconButton></Link>
                    
                </Grid>
                <Grid item>
                    <Link to="/log" className={classes.link}><Button className={classes.buttonsTop}>
                    Log</Button></Link>
                    <Link to="/download" className={classes.link}><Button className={classes.buttonsTop}>
                    See Previous Scrapes</Button></Link>
                </Grid>
            </Grid>
                <Grid container direction="row" justify="space-around">
                    <Link to="/upload-property" className={classes.link}><Button className={classes.buttonUpload}>
                        Upload Property Excel
                        </Button></Link>
                        <Link to="/upload-tenant" className={classes.link}><Button className={classes.buttonUpload}>
                            Upload Tenant Excel
                            </Button></Link>
                </Grid>
                <Status />
        </Grid>
    )
}
                
export default Home;

