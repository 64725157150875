import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import './Log.css'


function NewlineText(props) {
    const text = props.text;
    const newText = text.split('\n').map((str) => <p className="text">{str}</p>);

  return newText;
}

function stopScrape(){
    fetch('https://www.ironstone-tech.com/api/stop-scrape');
}

function Dlog() {
    const [err, setErr] = useState("");
    const [out, setOut] = useState("");
    useEffect (() => {
        fetch('https://www.ironstone-tech.com/api/detail-log').then(res => res.json()).then((result) =>{
            setErr(result.err);
            setOut(result.out);
        });
    }, []);

    return (
        <div className="page">
            <NewlineText text={err} />
            <hr/><hr/>
            <NewlineText text={out} />
        </div>
    );
};

export default Dlog;
